import { useState, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import { AuthProvider } from './state/providers/auth';
import Main from './components/Main';

import { themeConfig } from './config';

const theme = createTheme(themeConfig);

function App() {
  const [token, updateToken] = useState(null);

  const resetToken = useCallback(newToken => {
    if (!newToken) {
      localStorage.removeItem('token');
      updateToken(null);
    }
    else {
      localStorage.setItem('token', newToken);
      updateToken(newToken);
    }
  }, [updateToken]);

  return (
    <ThemeProvider theme={ theme }>
      <AuthProvider resetToken={ resetToken } token={ token }>
        <div className="App">
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Main />
          </Box>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
