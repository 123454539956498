const { 
    REACT_APP_NODE_ENV,
    REACT_APP_SERVER_URL
} = process.env;

console.log('process.env', process.env);

export const NODE_ENV = REACT_APP_NODE_ENV;
export const SERVER_URL = REACT_APP_SERVER_URL;

export const themeConfig = {
  palette: {
    primary: {
      main: '#800020',
      light: '$fffdd0',
    },
    secondary: {
      main: '#5f9ea0'
    },
    // text: {
    //   primary: '#181818',
    //   // secondary: styles.tt,
    //   // disabled: styles.ttt,
    //   // hint: styles.tttt,
    // }
  },
  typography: {
    allVariants: {
      color: '#242424'
    },
    fontSize: 12,
    fontFamily:
      [
        '"Cabin Variable"', 
        'sans-serif'
      ].join(','),
    h1: {
      letterSpacing: '0.02em',
    },
    h2: {
      letterSpacing: '0.02em',
    },
    h3: {
      letterSpacing: '0.02em',
    },
    h4: {
      letterSpacing: '0.02em',
    },
    h5: {
      letterSpacing: '0.02em'
    },
    h6: {
      letterSpacing: '0.02em'
    },
    subtitle1: {
      letterSpacing: '0.02em'
    },
    subtitle2: {
      letterSpacing: '0.02em'
    },
    body1: {
      letterSpacing: '0.02em'
    },
    body2: {
      letterSpacing: '0.02em'
    },
    button: {
      letterSpacing: '0.02em'
    },
    caption: {
      letterSpacing: '0.02em'
    },
    overline: {
      letterSpacing: '0.02em'
    },
  },
};
