import './index.scss';

function Main() {
  return (
    <div className="main-view-component-container">
      <h1>Download Bobolink</h1>

      <div className="supported-systems">

      </div>
    </div>
  );
}

export default Main;
